<template>
<div>
    <div class="bg-video">
        <video class="bg-video__content" autoplay playsinline @ended="goToMenu()">
            <source src="~/assets/videos/Intro_video.mp4" type="video/mp4">
            <!-- <source src="img/video.webm" type="video/webm"> -->
            Ihr Webbrowser unterstützt keine MP4 Videos! Bitte aktualisieren Sie ihn!
        </video>
    </div>
    <div class="button__skip">
        <Button 
        @click="goToMenu()"
        :title="$t('views.buttons.skip')"
        />
    </div>
</div>
</template>

<script>
import Button from 'components/shared/Button.vue';

export default {
  name: 'IntroVideo',
  components: {
    Button
  },
  methods: {
    goToMenu(){
      this.$router.push('/menu');
    }
  }
};
</script>

<style lang='scss' scoped>
.bg-video{
  position: absolute;
  bottom: 47px;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;

  &__content{
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}


.button__skip{
    position: absolute;
    right: 15%;
    bottom: 10%;
    width: 200px;
    text-transform: none !important;

    .product-button{
        width: 15rem !important;
        padding-left: 10px !important;
        text-transform: none !important;

    }
}

</style>
